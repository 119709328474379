export const formText = {
    email: {
        label: "Email",
        placeholder: "Email",
        errorMessage: "Niepoprawny adres email"
    },
    password: {
        label: "Hasło",
        placeholder: "Hasło",
        errorMessage: "Hasło musi mieć: minimum 8 znaków, jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny"
    },
    repeatPassword: {
        label: "Hasło",
        placeholder: "Hasło",
        errorMessage: "Hasła muszą być takie same"
    },
    name: {
        label: "Imię",
        placeholder: "Imię",
        errorMessage: "Niedozwolone znaki"
    },
    userName: {
        label: "Nazwa użytkownika",
        placeholder: "Nazwa użytkownika",
        errorMessage: "Niedozwolone znaki"
    }

}