export interface EventsData {
    [key: string]: EventData
}

export interface EventData {
    title: string
}

export enum SortTeams {
    TeamName,
    ApprovedStatus,
    ConfirmationStatus,
    SolutionStatus
}