export const formText = {
    email: {
        label: "Email",
        placeholder: "Email",
        errorMessage: "Niepoprawny adres email"
    },
    password: {
        label: "Hasło",
        placeholder: "Hasło",
        errorMessage: "Niedozwolone znaki"
    }
}