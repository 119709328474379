export const formText = {
    teamName: {
        label: "Nazwa zespołu",
        placeholder: "Nazwa zespołu",
        errorMessage: "SDKFJ"
    },
    email: {
        label: "Email",
        placeholder: "Email",
        errorMessage: "Wprowadź poprawny adres email"
    },
}