import App from "../App";
import { createBrowserRouter } from "react-router-dom";

import { AdminProtectedRoute } from "../Library";

//PAGES
import NotFound from "../Pages/NotFound/NotFound";
import InProgress from "../Pages/InProgress/InProgress";
import Teams from "../Pages/Teams/Teams";
import Dashboard from "../Pages/Home/Dashboard";
import Logout from "../Pages/Logout/Logout";
import MessagePage from "../Pages/MessagePage/MessagePage";
import Login from "../Pages/Login/Login";
import CreateAdmin from "../Pages/CreateAdmin/CreateAdmin";
import Users from "../Pages/Users/Users";
import RegisterTeam from "../Pages/RegisterTeam/RegisterTeam";
import EventList from "../Pages/EventsList/EventsList";
import Event from "../Pages/Event/Event";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <AdminProtectedRoute>
            <Dashboard />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/rejestracja",
        element: (
          <AdminProtectedRoute>
            <CreateAdmin />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/sukces/rejestracja",
        element: (
          <AdminProtectedRoute>
            <MessagePage
              title="Admin dodany"
              message="Nowy admin został pomyślnie dodany"
              buttonOneText="Dashboard"
              buttonOneLink="/"
              buttonTwoText="Drużyny"
              buttonTwoLink="/druzyny"
            />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/wydarzenia",
        element: (
          <AdminProtectedRoute>
            <EventList />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/wydarzenia/:eventName",
        element: (
          <AdminProtectedRoute>
            <Event />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/druzyny",
        element: (
          <AdminProtectedRoute>
            <Teams />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/druzyny/dodaj",
        element: (
          <AdminProtectedRoute>
            <RegisterTeam />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/sukces/rejestracja/druzyny",
        element: (
          <AdminProtectedRoute>
            <MessagePage
              title="Drużyna dodana"
              message="Drużyna została pomyślnie dodana. Podani uczestnicy dostali linki do drugiej części rejestracji na podane maile."
              buttonOneText="Dashboard"
              buttonOneLink="/"
              buttonTwoText="Drużyny"
              buttonTwoLink="/druzyny"
            />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/druzyna/:teamName",
        element: (
          <AdminProtectedRoute>
            <InProgress />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/uczestnicy",
        element: (
          <AdminProtectedRoute>
            <Users />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/uczestnik/dodaj",
        element: (
          <AdminProtectedRoute>
            <InProgress />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/uczestnik/:memberEmail",
        element: (
          <AdminProtectedRoute>
            <InProgress />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/wyloguj",
        element: (
          <AdminProtectedRoute>
            <Logout />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "/error/logout",
        element: (
          <AdminProtectedRoute>
            <MessagePage
              title="Błąd"
              message="Wystąpił błąd podczas wylogowywania"
              buttonOneText="Wróć do strony głównej"
              buttonOneLink="/"
            />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
