import React, { useEffect, useState } from "react";
import "./RegisterTeam.css";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import {
  Alert,
  AdminTeamService,
  SocialMedia,
  Page,
  Input,
  getEventStatus,
  EventStatus,
  replacePlaceholders,
  dateFormat,
  DateFormat,
  registrationStartDate,
  RegisterTeamRequestBody,
  ErrorBodyResponse,
  EmailRegexString,
} from "../../Library";

//ASSETS
import closeIcon from "../../Assets/close-cross.svg";
import { formText } from "./formText";
import addCircleIcon from "../../Assets/add-circle.svg";

interface Props {}

export default function RegisterTeam(props: Props) {
  const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [pickedEmails, setPickedEmails] = useState<string[]>([]);
  const [emailsError, setEmailsError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const pickedEmails = document.getElementsByClassName(
      "input__field--picked"
    ) as HTMLCollectionOf<HTMLInputElement>;
    if (pickedEmails.length > 0) {
      for (let i = 0; i < pickedEmails.length; i++) {
        const valueLength = pickedEmails[i].value.length;
        const paddingLeft = window
          .getComputedStyle(pickedEmails[i])
          .getPropertyValue("padding-left");
        const paddingRight = window
          .getComputedStyle(pickedEmails[i])
          .getPropertyValue("padding-right");
        pickedEmails[i].style.width = `calc(${
          valueLength + 1
        }ch + ${paddingLeft} + ${paddingRight})`;
      }
    }
  }, [pickedEmails]);

  const handleIconClick = (email: string) => {
    setPickedEmails((prev) => [...prev, email]);
    setEmailsError(null);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const form = e.currentTarget;
    const teamName = form.querySelector(
      "input[name='teamName']"
    ) as HTMLInputElement;

    teamName.reportValidity();
    if (!teamName.checkValidity()) {
      return;
    }

    const emailInputs = form.querySelectorAll(
      "input[name^='email']"
    ) as NodeListOf<HTMLInputElement>;
    let emails = Array.from(emailInputs).map((input) => input.value);

    const emailInput = emails[0];
    emails = emails.slice(1, emails.length);

    setEmailsError(null);

    if (emails.length < 2) {
      setEmailsError("W drużynie muszą być co najmniej 2 osoby");
      return;
    }

    if (emailInput !== "") {
      setEmailsError(
        "Pole email musi być puste. Jeżeli chcesz dodać więcej osób do drużyny, użyj przycisku z ikoną plusa"
      );
      return;
    }

    const uniqueEmails = new Set(emails);
    if (uniqueEmails.size !== emails.length) {
      setEmailsError("Musisz podać unikalne adresy email");
      return;
    }

    const data = Object.fromEntries(new FormData(form).entries());

    const body: RegisterTeamRequestBody = {
      teamName: data.teamName as string,
      teamMembersEmails: emails,
    };

    setInputsDisabled(true);

    AdminTeamService.registerTeam(body)
      .then((response) => {
        if (response.ok) {
          navigate(`/sukces/rejestracja/druzyny`);
        } else {
          response
            .json()
            .then((data: ErrorBodyResponse) => {
              setSubmitError(data.error);
              setInputsDisabled(false);
            })
            .catch(() => {
              setSubmitError("Błąd serwera");
              setInputsDisabled(false);
            });
        }
      })
      .catch(() => {
        setSubmitError("Błąd połączenia z serwerem");
        setInputsDisabled(false);
      });
  };

  const handleDeleteEmail = (index: number) => {
    const newPickedEmails = pickedEmails.filter((_, i) => i !== index);
    setPickedEmails(newPickedEmails);
  };

  return (
    <Page
      pageTitle="Rejestracja zespołu | HackArena"
      description="Strona do rejestracji zespołu"
    >
      <div className="register">
        {submitError && (
          <Alert
            title="Błąd"
            description="Wystąpił błąd podczas rejestracji:"
            message={submitError}
            buttonOneText="Spróbuj ponownie"
            buttonOneAction={() => {
              setSubmitError("");
            }}
          />
        )}
        <div className="register--content pagewidth">
          <h2 className="header header__yellow">Dodaj drużynę</h2>

          <form
            id="register_form"
            className="section--column-0"
            onSubmit={handleSubmit}
            noValidate
          >
            <Input
              pageText={formText.teamName}
              id="team_name"
              name="teamName"
              showError={showErrors}
              minLength={1}
              maxLength={40}
              inputDisabled={inputsDisabled}
            />
            <Input
              pageText={formText.email}
              id="email"
              name="email"
              type="email"
              icon={addCircleIcon}
              onIconClick={handleIconClick}
              showError={false}
              maxLength={60}
              inputDisabled={inputsDisabled || pickedEmails.length === 3}
              pattern={EmailRegexString}
            />
            <div
              className={`register__emails${
                pickedEmails.length > 0 ? " register__email--margin-bottom" : ""
              }`}
            >
              {pickedEmails.map((email, index) => (
                <div className="input__wrapper" key={index}>
                  <input
                    type="email"
                    name={`email_${index}`}
                    readOnly
                    disabled={inputsDisabled}
                    className="input__element input__field input__field--picked"
                    value={email}
                  />
                  <img
                    src={closeIcon}
                    alt="Usuń"
                    className={inputsDisabled ? "not-clickable" : ""}
                    onClick={() => handleDeleteEmail(index)}
                  />
                </div>
              ))}
            </div>
            <input
              className="input__element input__button"
              type="submit"
              onClick={() => setShowErrors(true)}
              disabled={inputsDisabled}
              value={inputsDisabled ? "Przekierowuję..." : "Zarejestruj"}
            />
            <span
              className={`input__span${
                emailsError ? " input__span--visible" : ""
              }`}
            >
              {emailsError}
            </span>
          </form>
        </div>
      </div>
    </Page>
  );
}
