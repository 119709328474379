import "./App.css";

import { useRef, useState, useEffect } from "react";
import { Outlet, Link, ScrollRestoration } from "react-router-dom";

//COMPOENNTS AND TYPES
import { AdminAuthProvider, useWindowWidth, NavBar, NavItems } from "./Library";
//ASSETS
import Logo from "./Assets/logo.svg";
import SGGW from "./Assets/sggw_logo_white.png";
import text from "./Assets/text.json";

const TopBar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navItems: NavItems = text.nav;

  return (
    <div className="topbar__wrapper">
      <div className="topbar pagewidth">
        <div style={{ height: '100%' }} className="section--row-1" >
          <Link to="/">
            <img src={Logo} alt="HackArena" />
          </Link>
          <a href="https://www.sggw.edu.pl">
            <img src={SGGW} alt="SGGW" />
          </a>

        </div>
        <NavBar showSideBar={showSidebar} setShowSidebar={setShowSidebar} navItems={navItems} />
      </div>
    </div>
  )
}

function App() {
  const appContentRef = useRef<HTMLDivElement | null>(null);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const topBarHeight = document.querySelector('.topbar__wrapper')?.clientHeight || 0;
    if (windowWidth <= 768 && appContentRef.current) {
      appContentRef.current.style.marginTop = `${topBarHeight}px`;
    } else {
      appContentRef.current?.style.removeProperty('margin-top');
    }
  }, [windowWidth])

  return (
    <AdminAuthProvider>
      <div className="app">
        <ScrollRestoration />
        <div className="app__admin">
          ADMIN PANEL
        </div>
        <TopBar />
        <div ref={appContentRef} className="app--content">
          <Outlet />
        </div>
      </div>
    </AdminAuthProvider>
  );
}

export default App;
