import { useEffect, useState } from 'react'
import './EventsList.css'

//COMPONENTS
import { EventListBanner, Page } from '../../Library'
import { EventBannerData, EventsData, PageText } from './types'

//ASSETS
import text from '../../Library/Assets/Text/main.json'
import { events } from './EventsData'

function EventList(): JSX.Element {
  const pageText: PageText = text.eventList;
  const [eventsData, setEventsData] = useState<EventsData | null>(null)
  useEffect(() => {
    //TODO: get events data from backend
    setEventsData(events);
  }, [])

  return (
    <Page pageTitle={pageText.meta.title} description={pageText.meta.description}>
      <div className='events pagewidth'>
        <div className='events__header'>
          <h2 className='header__yellow header__wider header__taller'>{pageText.title}</h2>
        </div>
        {
          (eventsData === null || eventsData.count === 0) &&
          <h5 className='events__noevents'>{pageText.noEvents}</h5>
        }
        {
          eventsData !== null && eventsData.count > 0 &&
          <>
            <h3 className='header__yellow'>{pageText.upcoming}</h3>
            {
              eventsData.upcoming.map((event: EventBannerData, index: number) => (
                <EventListBanner key={index} image={event.banner} title={event.title} url={event.url} isPresent={true} />
              ))
            }
            <h3 className='header__white'>{pageText.finished}</h3>
            {
              eventsData.finished.map((event: EventBannerData, index: number) => (
                <EventListBanner key={index} image={event.banner} title={event.title} url={event.url} />
              ))
            }
          </>
        }
      </div>
    </Page>
  )
}

export default EventList