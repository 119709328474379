import { FormEvent, useEffect, useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router";

//COMPONENTS
import {
  AdminAuthenticationService, Alert, Page, Input, LoginRequestBody,
  ErrorBodyResponse, useAdminAuth, EmailRegexString,
  PasswordRegexString
} from "../../Library";

//ASSETS
import { formText } from "./formText"

function Login() {
  const [error, setError] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
  const { login, isAuthenticated } = useAdminAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated])

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = new FormData(form);
    const dataObject = Object.fromEntries(data);

    const body: LoginRequestBody = {
      email: dataObject.email as string,
      password: dataObject.password as string
    };

    setInputsDisabled(true);

    AdminAuthenticationService.login(body)
      .then((response) => {
        if (response.status === 202) {
          login();
        } else {
          response.json().then((data: ErrorBodyResponse) => {
            setError(data.error);
            setInputsDisabled(false);
          }).catch(() => {
            setError("Błąd serwera");
            setInputsDisabled(false);
          });
        }
      })
      .catch((error) => {
        setError("Błąd połączenia z serwerem");
        setInputsDisabled(false);
      });
  };

  return (
    <Page pageTitle="Logowanie | HackArena" description="Zaloguj się na swoje konto HackArena" noIndex>
      {error &&
        <Alert
          title="Błąd"
          description="Wystąpił błąd podczas logowania:"
          message={error}
          buttonOneText="Spróbuj ponownie"
          buttonOneAction={() => { setError(""); }}
        />
      }
      <div className="login pagewidth">
        <h2 className="header header__yellow">Logowanie</h2>
        <form className="section--column-0" onSubmit={handleSubmit}>
          <Input
            pageText={formText.email}
            id="email"
            name="email"
            type="email"
            showError={showErrors}
            maxLength={70}
            inputDisabled={inputsDisabled}
            pattern={EmailRegexString}
          />
          <Input
            pageText={formText.password}
            id="password"
            name="password"
            type="password"
            showError={showErrors}
            inputDisabled={inputsDisabled}
            minLength={8}
            maxLength={70}
            pattern={PasswordRegexString}
          />
          <input
            type="submit"
            className="input__element input__button"
            onClick={() => setShowErrors(true)}
            disabled={inputsDisabled}
            value={inputsDisabled ? "Przekierowuję..." : "Zaloguj się"}
          />
        </form>
      </div>
    </Page>
  )
}


export default Login;
