import React, { useEffect, useState } from 'react'
import './Teams.css'
import { useNavigate } from 'react-router-dom'

//COMPONENTS
import {
    AdminTeamService, Page, Alert, ApprovedStatus, TeamsData,
    GetAllTeamsResponseBody, Button
} from '../../Library'
import DropDown from '../../Library/Components/DropDown/DropDown'

// ASSETS
import { SortTeams } from './types'
import MembersCountIcon from "../../Assets/membersCount.svg"
import VerifiedIcon from "../../Assets/verified.svg"
import NotVerifiedIcon from "../../Assets/notVerified.svg"
import VerificationStatusIcon from "../../Assets/verificationStatus.svg"
import PendingIcon from "../../Assets/approvePending.svg"
import ApproveStatusIcon from "../../Assets/approveStatus.svg"
import ApprovedIcon from "../../Assets/approved.svg"
import OptionsHeaderIcon from "../../Assets/optionHeader.svg"
import OptionsIcon from "../../Assets/options.svg"
import RejectedIcon from "../../Assets/rejected.svg"

function Teams() {
    const [teamsData, setTeamsData] = useState<TeamsData | null>(null)
    const [teamsDataOriginal, setTeamsDataOriginal] = useState<TeamsData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [currentSort, setCurrentSort] = useState<{ column: SortTeams, sortBy: "asc" | "dsc" } | null>(null)
    const [dropDownEvent, setDropDownEvent] = useState<React.MouseEvent | null>(null)
    const [dropDownOptions, setDropDownOptions] = useState<{ title: string, onClick: Function }[] | null>(null)
    const navigate = useNavigate();

    useEffect(() => {
        AdminTeamService.getAllTeams().then((response) => {
            if (response.status === 202) {
                response.json().then((data: GetAllTeamsResponseBody) => {
                    const sortedData = data.teams.sort((a, b) => a.teamName.localeCompare(b.teamName))
                    setTeamsData({ teams: sortedData })
                    setTeamsDataOriginal({ teams: sortedData })
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }, [])

    const handleSort = (sort: SortTeams) => () => {
        let unsortedTeams = teamsData?.teams;

        // on second click of the same sort type, reverse the order using currentsort state
        if (currentSort?.column === sort) {
            unsortedTeams?.reverse();
            setCurrentSort({ column: sort, sortBy: currentSort.sortBy === "asc" ? "dsc" : "asc" });
        } else {
            let sortedTeams = unsortedTeams?.sort((a, b) => {
                switch (sort) {
                    case SortTeams.TeamName:
                        return a.teamName.localeCompare(b.teamName)
                    case SortTeams.MemberCount:
                        return a.numberOfUsers - b.numberOfUsers
                    case SortTeams.VerifiedStatus:
                        return a.verified === b.verified ? 0 : a.verified ? -1 : 1
                    case SortTeams.ApprovalStatus:
                        return a.verified === b.verified ? 0 : a.verified ? -1 : 1
                    default:
                        return 0
                }
            })

            setTeamsData(sortedTeams ? { teams: sortedTeams } : null);
            setCurrentSort({ column: sort, sortBy: "asc" });
        }
    }


    const handleApprove = (teamName: string) => {
        AdminTeamService.approveTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);
                if (team && teamsData) {
                    team.approved = ApprovedStatus.Approved;
                    setTeamsData({ teams: teamsData?.teams });
                }
            } else {
                setError("Nie udało się zatwierdzić drużyny");
            }
        }).catch((error) => {
            setError(error);
        })
    }

    const handleDeny = (teamName: string) => {
        AdminTeamService.rejectTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);

                if (team && teamsData) {
                    team.approved = ApprovedStatus.Rejected;
                    setTeamsData({ teams: teamsData?.teams });
                }
            } else {
                setError("Nie udało się odrzucić drużyny");
            }
        }).catch((error) => {
            setError(error);
        })
    }

    const handleDelete = (teamName: string) => {
        AdminTeamService.deleteTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);
                if (team && teamsData) {
                    const index = teamsData.teams.indexOf(team);
                    teamsData.teams.splice(index, 1);
                    setTeamsData({ teams: teamsData.teams });
                }
            } else {
                setError("Nie udało się usunąć drużyny");
            }
        }).catch((error) => {
            setError("Nie udało się usunąć drużyny");
        })
    }


    const handleTeamSearch = (search: string) => {
        let filteredTeams = teamsDataOriginal?.teams.filter((team) => team.teamName.toLowerCase().includes(search.toLowerCase()));
        setTeamsData(filteredTeams ? { teams: filteredTeams } : null);
    }

    const handleShowDropDown = (e: React.MouseEvent, options: { title: string, onClick: Function }[]) => {
        if (e.target === dropDownEvent?.target) {
            setDropDownEvent(null);
            return;
        }
        setDropDownEvent(e);
        setDropDownOptions(options);
    }

    return (
        <Page pageTitle='Drużyny | HackArena' description='Lista drużyn'>
            {
                error &&
                <Alert
                    title='Błąd'
                    message={error}
                    buttonOneText='Zamknij'
                    buttonOneAction={() => setError(null)}
                />
            }
            <DropDown clickEvent={dropDownEvent} options={dropDownOptions} />
            <div className='section--column-1 pagewidth teams'>
                <h1 className='header__yellow'>Drużyny</h1>
                <div className='teams__handlebar'>
                    <span>Liczba drużyn: <b>{teamsData?.teams.length}</b></span>
                    <input
                        type='text'
                        placeholder='Szukaj drużyny'
                        onChange={(e) => handleTeamSearch(e.target.value)}
                    />
                    <Button onClick={() => navigate(`/druzyny/dodaj`)} className='btn btn__primary' border>Dodaj drużynę</Button>
                </div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th className='clickable text-align-left' onClick={handleSort(SortTeams.TeamName)}>Nazwa</th>
                            <th className='clickable' onClick={handleSort(SortTeams.MemberCount)} >
                                <div title="Liczba osób w drużynie">
                                    <img src={MembersCountIcon} alt="Members count" />
                                </div>
                            </th>
                            <th className='clickable' onClick={handleSort(SortTeams.VerifiedStatus)} title="Status weryfikacji">
                                <div>
                                    <img src={VerificationStatusIcon} alt="Verification status" />
                                </div>
                            </th>
                            <th className='clickable' onClick={handleSort(SortTeams.ApprovalStatus)} title="Status zatwierdzenia" >
                                <div>
                                    <img src={ApproveStatusIcon} alt="Approve status" />
                                </div>
                            </th>
                            <th>
                                <div title="Opcje" >
                                    <img src={OptionsHeaderIcon} alt="Options" />
                                </div>
                            </th>
                        </tr>
                        {
                            teamsData?.teams.map((team, index) => (
                                <tr className='team' key={index}>
                                    <td className='clickable text-align-left' onClick={() => navigate(`/druzyna/${team.teamName}`)}>{team.teamName}</td>
                                    <td>{team.numberOfUsers}</td>
                                    <td>
                                        <div>
                                            {
                                                team.verified ? (
                                                    <img src={VerifiedIcon} alt="Verified" title="Drużyna zweryfikowana" />
                                                ) : (
                                                    <img src={NotVerifiedIcon} alt="Not verified" title="Drużyna niezweryfikowana" />
                                                )
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='clickable' onClick={(e) => handleShowDropDown(e, [
                                            {
                                                title: "Zatwierdź",
                                                onClick: () => handleApprove(team.teamName)
                                            },
                                            {
                                                title: "Odrzuć",
                                                onClick: () => handleDeny(team.teamName)
                                            }
                                        ])}>
                                            {
                                                team.approved === ApprovedStatus.Approved &&
                                                <img src={ApprovedIcon} alt="Approved" title="Drużyna zatwierdzona" />
                                            }
                                            {
                                                team.approved === ApprovedStatus.Pending &&
                                                <img src={PendingIcon} alt="Pending" title="Oczekuje na zatwierdzenie" />
                                            }
                                            {
                                                team.approved === ApprovedStatus.Rejected &&
                                                <img src={RejectedIcon} alt="Rejected" title="Drużyna odrzucona" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='clickable' onClick={(e) => handleShowDropDown(e, [

                                        ])}>
                                            <img src={OptionsIcon} alt="Options" title="Opcje" />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </Page>
    )
}

export default Teams