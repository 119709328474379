import { useEffect, useState } from 'react'
import './Users.css'
import { useNavigate } from 'react-router-dom'

import {
    UsersData, getAllUsersResponseBody, AdminUserService, Page, Alert,
    Button
} from '../../Library'

import { SortUsers } from './types'
import VerifiedIcon from "../../Assets/verified.svg"
import NotVerifiedIcon from "../../Assets/notVerified.svg"
import VerificationStatusIcon from "../../Assets/verificationStatus.svg"
import OptionsHeaderIcon from "../../Assets/optionHeader.svg"
import OptionsIcon from "../../Assets/options.svg"

function Users() {
    const [usersData, setUsersData] = useState<UsersData | null>(null)
    const [usersDataOriginal, setUsersDataOriginal] = useState<UsersData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [currentSort, setCurrentSort] = useState<{ column: SortUsers, sortBy: "asc" | "dsc" } | null>(null)
    const navigate = useNavigate();

    useEffect(() => {
        AdminUserService.getAllUsers().then((response) => {
            if (response.status === 202) {
                response.json().then((data: getAllUsersResponseBody) => {
                    const sortedData = data.users.sort((a, b) => a.firstName.localeCompare(b.firstName))
                    setUsersData({ users: sortedData })
                    setUsersDataOriginal({ users: sortedData })
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }, [])

    const handleSort = (sort: SortUsers) => () => {
        let unsortedTeams = usersData?.users;

        // on second click of the same sort type, reverse the order using currentsort state
        if (currentSort?.column === sort) {
            unsortedTeams?.reverse();
            setCurrentSort({ column: sort, sortBy: currentSort.sortBy === "asc" ? "dsc" : "asc" });
        } else {
            let sortedTeams = unsortedTeams?.sort((a, b) => {
                switch (sort) {
                    case SortUsers.TeamName:
                        return a.teamName.localeCompare(b.teamName)
                    case SortUsers.Email:
                        return a.email.localeCompare(b.email)
                    case SortUsers.VerifiedStatus:
                        return a.verified === b.verified ? 0 : a.verified ? -1 : 1
                    case SortUsers.FirstName:
                        return a.firstName.localeCompare(b.firstName)
                    case SortUsers.LastName:
                        return a.lastName.localeCompare(b.lastName)
                    default:
                        return 0
                }
            })

            setUsersData(sortedTeams ? { users: sortedTeams } : null);
            setCurrentSort({ column: sort, sortBy: "asc" });
        }
    }

    const handleUserSearch = (search: string) => {
        let filteredUsers = usersDataOriginal?.users.filter((user) => (
            user.email.toLowerCase().includes(search.toLowerCase()) ||
            user.firstName.toLowerCase().includes(search.toLowerCase()) ||
            user.lastName.toLowerCase().includes(search.toLowerCase())
        ));
        setUsersData(filteredUsers ? { users: filteredUsers } : null);
    }

    return (
        <Page pageTitle='Użytkownicy | HackArena' description='Lista użytkowników'>
            {
                error &&
                <Alert
                    title='Błąd'
                    message={error}
                    buttonOneText='Zamknij'
                    buttonOneAction={() => setError(null)}
                />
            }
            <div className='section--column-1 pagewidth teams'>
                <h1 className='header__yellow'>Uczestnicy</h1>
                <div className='teams__handlebar'>
                    <span>Liczba użytkowników: <b>{usersData?.users.length}</b></span>
                    <input
                        type='text'
                        placeholder='Szukaj uczestnika'
                        onChange={(e) => handleUserSearch(e.target.value)}
                    />
                    <Button onClick={() => navigate(`/uczestnik/dodaj`)} className='btn btn__primary' border>Dodaj uczestnika</Button>
                </div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th className='clickable text-align-left' onClick={handleSort(SortUsers.FirstName)} >Imię</th>
                            <th className='clickable text-align-left' onClick={handleSort(SortUsers.LastName)} >Nazwisko</th>
                            <th className='clickable text-align-left' onClick={handleSort(SortUsers.Email)} >Email</th>
                            <th className='clickable text-align-left' onClick={handleSort(SortUsers.TeamName)} >Drużyna</th>
                            <th className='clickable' onClick={handleSort(SortUsers.VerifiedStatus)}>
                                <div>
                                    <img src={VerificationStatusIcon} alt="Verification status" title="Status weryfikacji" />
                                </div>
                            </th>
                            <th>
                                <div>
                                    <img src={OptionsHeaderIcon} alt="Options" title="Opcje" />
                                </div>
                            </th>
                        </tr>
                        {
                            usersData?.users.map((user, index) => (
                                <tr className='team' key={index}>
                                    <td className='clickable text-align-left' onClick={() => navigate(`/uczestnik/${user.email}`)}>{user.verified ? user.firstName : "-"}</td>
                                    <td className='clickable text-align-left' onClick={() => navigate(`/uczestnik/${user.email}`)}>{user.verified ? user.lastName : "-"}</td>
                                    <td className='clickable text-align-left' onClick={() => navigate(`/uczestnik/${user.email}`)}>{user.email}</td>
                                    <td className='clickable text-align-left' onClick={() => navigate(`/druzyna/${user.teamName}`)}>{user.teamName}</td>
                                    <td>
                                        <div>
                                            {
                                                user.verified ? (
                                                    <img src={VerifiedIcon} alt="Verified" title="Drużyna zweryfikowana" />
                                                ) : (
                                                    <img src={NotVerifiedIcon} alt="Not verified" title="Drużyna niezweryfikowana" />
                                                )
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='clickable' >
                                            <img src={OptionsIcon} alt="Options" title="Opcje" />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </Page>
    )
}

export default Users