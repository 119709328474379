import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AdminAuthenticationService, useAdminAuth } from '../../Library'

function Logout() {
    const navigate = useNavigate()
    const { logout } = useAdminAuth();

    useEffect(() => {
        AdminAuthenticationService.logout().then(() => {
            logout()
        }).catch(() => {
            navigate('/error/logout')
        })
    }, [])

    return (
        <></>
    )
}

export default Logout