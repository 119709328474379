import React, { useEffect, useState } from 'react'
import './Event.css'
import { useNavigate, useParams } from 'react-router-dom'

//COMPONENTS
import {
    AdminTeamService, Page, Alert, ApprovedStatus, TeamsData,
    GetAllTeamsResponseBody, Button,
    GetAllEventTeamsResponseBody,
    EventTeamsData,
    Select,
    FileUploader,
    dateFormat,
    DateFormat
} from '../../Library'
import DropDown from '../../Library/Components/DropDown/DropDown'

// ASSETS
import { EventData, EventsData, SortTeams } from './types'
import VerifiedIcon from "../../Assets/verified.svg"
import NotVerifiedIcon from "../../Assets/notVerified.svg"
import VerificationStatusIcon from "../../Assets/verificationStatus.svg"
import PendingIcon from "../../Assets/approvePending.svg"
import ApprovedIcon from "../../Assets/approved.svg"
import RejectedIcon from "../../Assets/rejected.svg"
import FileStatusIcon from "../../Assets/file-status.svg"
import FileNotSentIcon from "../../Assets/file-not-sent.svg"
import FileSentIcon from "../../Assets/file-sent.svg"
import ConfirmationStatusIcon from "../../Assets/confirmation-status.svg"
import ConfirmationYesIcon from "../../Assets/confirmation-yes.svg"
import ConfirmationNoIcon from "../../Assets/confirmation-no.svg"
import NoDownloadIcon from "../../Assets/no-download.svg"
import { events } from './eventsData'
import DownloadIcon from "../../Assets/download.svg"
import DownloadStatusIcon from "../../Assets/download-status.svg"
import ApproveStatusIcon from "../../Assets/approveStatus.svg"

function Event() {
    const [teamsData, setTeamsData] = useState<EventTeamsData | null>(null)
    const [teamsDataOriginal, setTeamsDataOriginal] = useState<EventTeamsData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [currentSort, setCurrentSort] = useState<{ column: SortTeams, sortBy: "asc" | "dsc" } | null>(null)
    const [dropDownEvent, setDropDownEvent] = useState<React.MouseEvent | null>(null)
    const [dropDownOptions, setDropDownOptions] = useState<{ title: string, onClick: Function }[] | null>(null)
    const [teamNames, setTeamNames] = useState<string[]>([]);
    const navigate = useNavigate();
    const [eventData, setEventData] = useState<EventData | null>(null)
    const { eventName } = useParams()

    useEffect(() => {
        if (eventName) {
            const event = events[eventName]
            setEventData(event)
        }
    }, [eventName])

    useEffect(() => {
        AdminTeamService.getEventTeams().then((response) => {
            if (response.status === 202) {
                response.json().then((data: GetAllEventTeamsResponseBody) => {
                    const sortedData = data.teams.sort((a, b) => a.teamName.localeCompare(b.teamName))
                    console.log(sortedData)
                    sortedData.map((team) => {
                        if (team.createdAt === "0001-01-01T00:00:00Z") {
                            team.createdAt = "-"
                        } else {
                            team.createdAt = dateFormat(new Date(team.createdAt), DateFormat.FULL)
                        }
                    })
                    setTeamsData({ teams: sortedData })
                    setTeamsDataOriginal({ teams: sortedData })
                }).catch((error) => {
                    setError(error);
                })
            }
        }).catch((error) => {
            setError(error);
        })
    }, [])

    const handleSort = (sort: SortTeams) => () => {
        let unsortedTeams = teamsData?.teams;

        // on second click of the same sort type, reverse the order using currentsort state
        if (currentSort?.column === sort) {
            unsortedTeams?.reverse();
            setCurrentSort({ column: sort, sortBy: currentSort.sortBy === "asc" ? "dsc" : "asc" });
        } else {
            let sortedTeams = unsortedTeams?.sort((a, b) => {
                switch (sort) {
                    case SortTeams.TeamName:
                        return a.teamName.localeCompare(b.teamName)
                    case SortTeams.ConfirmationStatus:
                        return a.confirmationStatus ? -1 : 1
                    case SortTeams.SolutionStatus:
                        return a.solutionStatus ? -1 : 1
                    default:
                        return 0
                }
            })

            setTeamsData(sortedTeams ? { teams: sortedTeams } : null);
            setCurrentSort({ column: sort, sortBy: "asc" });
        }
    }


    const handleConfirm = (teamName: string) => {
        AdminTeamService.confirmTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);
                if (team && teamsData) {
                    team.confirmationStatus = true;
                    setTeamsData({ teams: teamsData?.teams });
                }
            } else {
                setError("Nie udało się zatwierdzić drużyny");
            }
        }).catch((error) => {
            setError("Nie udało się zatwierdzić drużyny");
        })
    }

    const handleTeamSearch = (search: string) => {
        let filteredTeams = teamsDataOriginal?.teams.filter((team) => team.teamName.toLowerCase().includes(search.toLowerCase()));
        setTeamsData(filteredTeams ? { teams: filteredTeams } : null);
    }

    const handleShowDropDown = (e: React.MouseEvent, options: { title: string, onClick: Function }[]) => {
        if (e.target === dropDownEvent?.target) {
            setDropDownEvent(null);
            return;
        }
        setDropDownEvent(e);
        setDropDownOptions(options);
    }

    const handleDownload = (teamName: string) => {
        AdminTeamService.downloadSolution(teamName).then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${teamName}.zip`;
                    a.click();
                })
            } else {
                setError("Nie udało się pobrać odpowiedzi");
            }
        }).catch((error) => {
            setError(error);
        })
    }



    const handleApprove = (teamName: string) => {
        AdminTeamService.approveTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);
                if (team && teamsData) {
                    team.approvedStatus = ApprovedStatus.Approved;
                    setTeamsData({ teams: teamsData?.teams });
                }
            } else {
                setError("Nie udało się zatwierdzić drużyny");
            }
        }).catch((error) => {
            setError(error);
        })
    }

    const handleDeny = (teamName: string) => {
        AdminTeamService.rejectTeam(teamName).then((response) => {
            if (response.status === 200) {
                const team = teamsData?.teams.find((team) => team.teamName === teamName);

                if (team && teamsData) {
                    team.approvedStatus = ApprovedStatus.Rejected;
                    setTeamsData({ teams: teamsData?.teams });
                }
            } else {
                setError("Nie udało się odrzucić drużyny");
            }
        }).catch((error) => {
            setError(error);
        })
    }

    const handleDownloadAll = () => {
        AdminTeamService.downloadAllSolutions().then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `all.zip`;
                    a.click();
                })
            } else {
                setError("Nie udało się pobrać odpowiedzi");
            }
        }).catch((error) => {
            setError(error);
        })
    }

    const handleSendFile = (file: File): Promise<void> => {
        if (teamNames.length === 0) {
            return new Promise((resolve, reject) => {
                reject(new Error("Nie wybrano drużyn"));
            })
        }
        return new Promise((resolve, reject) => {
            teamNames.forEach((teamName) => {
                AdminTeamService.uploadMatchFile(teamName, file).then((response) => {
                    if (response.status === 201) {
                        const team = teamsData?.teams.find((team) => team.teamName === teamName);
                        if (team && teamsData) {
                            team.solutionStatus = true;
                            setTeamsData({ teams: teamsData?.teams });
                        }
                    } else {
                        reject(new Error("Nie udało się przesłać pliku"));
                    }
                }).catch((error) => {
                    reject(new Error("Nie udało się przesłać pliku"));
                });
            })
            resolve();
            setTeamNames([]);
        })
    }

    const handleAddTeamToSendGroup = (teamName: string) => {
        if (!teamNames.includes(teamName)) {
            setTeamNames([...teamNames, teamName]);
        } else {
            setTeamNames(teamNames.filter((name) => name !== teamName));
        }
    }

    return (
        <Page pageTitle={`${eventData?.title} | HackArena`} description='Lista drużyn'>
            {
                error &&
                <Alert
                    title='Błąd'
                    message={error}
                    buttonOneText='Zamknij'
                    buttonOneAction={() => setError(null)}
                />
            }

            <DropDown clickEvent={dropDownEvent} options={dropDownOptions} />
            <div className='section--column-1 pagewidth teams'>
                <h1 className='header__yellow'>{eventData?.title}</h1>
                <div className='teams__handlebar'>
                    <span>Liczba drużyn: <b>{teamsData?.teams.length}</b></span>
                    <input
                        type='text'
                        placeholder='Szukaj drużyny'
                        onChange={(e) => handleTeamSearch(e.target.value)}
                    />
                    <Button onClick={handleDownloadAll} className='btn btn__primary' border>Pobierz wszystkie</Button>
                </div>
                <table className='table'>
                    <tbody>
                        <tr>
                            <th className='clickable text-align-left' onClick={handleSort(SortTeams.TeamName)}>Nazwa</th>
                            <th className='clickable' onClick={handleSort(SortTeams.ApprovedStatus)} title="Status zatwierdzenia" >
                                <div>
                                    <img src={ApproveStatusIcon} alt="Approve status" />
                                </div>
                            </th>
                            <th className='clickable' onClick={handleSort(SortTeams.ConfirmationStatus)} >
                                <div title="Status potwierdzenia udziału">
                                    <img src={ConfirmationStatusIcon} alt="Confirmation status" />
                                </div>
                            </th>
                            <th className='clickable' title="Ściągnij odpowiedź" onClick={handleSort(SortTeams.SolutionStatus)}>
                                <div>
                                    <img src={DownloadStatusIcon} alt="Download solution" />
                                </div>
                            </th>
                            <th title="Przesłane o">
                                <div>
                                    <img src={FileStatusIcon} alt="File status" />
                                </div>
                            </th>
                        </tr>
                        {
                            teamsData?.teams.map((team, index) => (
                                <tr className='team' key={index}>
                                    <td className='clickable text-align-left' onClick={() => handleAddTeamToSendGroup(team.teamName)}>{team.teamName}</td>
                                    <td>
                                        <div className='clickable' onClick={(e) => handleShowDropDown(e, [
                                            {
                                                title: "Zatwierdź",
                                                onClick: () => handleApprove(team.teamName)
                                            },
                                            {
                                                title: "Odrzuć",
                                                onClick: () => handleDeny(team.teamName)
                                            }
                                        ])}>
                                            {
                                                team.approvedStatus === ApprovedStatus.Approved &&
                                                <img src={ApprovedIcon} alt="Approved" title="Drużyna zatwierdzona" />
                                            }
                                            {
                                                team.approvedStatus === ApprovedStatus.Pending &&
                                                <img src={PendingIcon} alt="Pending" title="Oczekuje na zatwierdzenie" />
                                            }
                                            {
                                                team.approvedStatus === ApprovedStatus.Rejected &&
                                                <img src={RejectedIcon} alt="Rejected" title="Drużyna odrzucona" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='clickable' onClick={(e) => handleShowDropDown(e, [
                                            {
                                                title: "Zatwierdź",
                                                onClick: () => handleConfirm(team.teamName)
                                            }
                                        ])}>
                                            {
                                                team.confirmationStatus === true &&
                                                <img src={ConfirmationYesIcon} alt="Confirmed" title="Drużyna potwierdzona" />
                                            }
                                            {
                                                team.confirmationStatus === false &&
                                                <img src={ConfirmationNoIcon} alt="UnConfirmed" title="Oczekuje na potwierdzenie" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className={`${team.solutionStatus ? "clickable" : "not-clickable"}`} onClick={() => handleDownload(team.teamName)}>
                                            {
                                                team.solutionStatus ? (
                                                    <img src={DownloadIcon} alt="Download" title="Ściągnij odpowiedź" />
                                                ) : (
                                                    <img src={NoDownloadIcon} alt="Not sent" title="" />
                                                )
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "25ch" }}>
                                        <div >
                                            {team.createdAt}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className='event__send-file'>
                    <h2 className="header header__yellow">Prześlij plik</h2>
                    <div className='send-file__teams'>
                        {teamNames.map((teamName, index) => (
                            <span key={index} onClick={() => setTeamNames(prev => prev.filter(elem => elem !== teamName))}>{teamName}</span>
                        ))}
                    </div>
                    <FileUploader sendFile={handleSendFile} fileTypes={["json"]} />
                </div>
            </div>
        </Page>
    )
}

export default Event