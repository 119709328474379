import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import { Page, Button } from "../../Library";

function Dashboard() {
  const navigate = useNavigate();

  return (
    <Page pageTitle="Dashboard | HackArena" description="Home page">
      <div className="section--column-1 pagewidth">
        <h1 className="header__yellow">Dashboard</h1>
        <Button
          onClick={() => navigate("/rejestracja")}
          className="btn btn__primary"
          border
        >
          Dodaj admina
        </Button>
      </div>
    </Page>
  );
}

export default Dashboard;
