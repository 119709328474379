import "./NotFound.css";
import { useNavigate } from "react-router";

//COMPONENTS
import { Page, Button } from "../../Library";

function NotFound() {
  const navigation = useNavigate();

  return (
    <Page pageTitle="Błąd 404 | HackArena" description="Strona, którą próbujesz otworzyć nie istnieje" noIndex>
      <div className="notfound pagewidth">
        <h2 className="header__yellow">Błąd 404</h2>
        <h6>Strona, którą próbujesz otworzyć nie istnieje</h6>
        <div>
          <Button className="btn btn__primary btn__primary-border" onClick={() => navigation("/")}>Strona główna</Button>
          <Button className="btn btn__secondary" onClick={() => navigation(-1)}>Wróć</Button>
        </div>
      </div>
    </Page>
  )
}

export default NotFound;
