import { FormEvent, useState } from 'react'
import './CreateAdmin.css'
import { useNavigate } from 'react-router-dom';

//COMPONENTS AND TYPES
import {
    AdminAuthenticationService, Page, AdminRegisterRequestBody, Alert, Input, EmailRegexString,
    PasswordRegexString, TextAndNumbersRegexString, ErrorBodyResponse, TextRegexString
} from '../../Library';

//ASSETS
import { formText } from './formText';

function CreateAdmin() {
    const [serverError, setServerError] = useState<string | null>(null);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>("");
    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setSubmitError("");

        const data = Object.fromEntries(new FormData(e.currentTarget));

        if (data.password !== data.repeatPassword) {
            setSubmitError("Podane hasła nie są takie same")
            return;
        }

        const body: AdminRegisterRequestBody = {
            password: data.password as string,
            email: data.email as string,
            userName: data.userName as string,
            name: data.name as string
        }

        setInputsDisabled(true);

        AdminAuthenticationService.register(body).then((response) => {
            if (response.status === 200) {
                navigate("/sukces/rejestracja")
            } else {
                response.json().then((data: ErrorBodyResponse) => {
                    setServerError(data.error)
                    setInputsDisabled(false)
                }).catch(() => {
                    setServerError("Błąd serwera")
                    setInputsDisabled(false)
                })
            }
        }).catch((error) => {
            setServerError("Błąd połączenia z serwerem")
            setInputsDisabled(false)
        })
    };

    return (
        <Page pageTitle="Dodaj admina | HackArena" description="Strona do dodawania kont administratorów">
            {serverError &&
                <Alert
                    title="Błąd"
                    description="Wystąpił błąd podczas tworzenia admina:"
                    message={serverError}
                    buttonOneText="Spróbuj ponownie"
                    buttonOneAction={() => { setServerError(""); }}
                />
            }
            <div className='createadmin pagewidth section--column-1'>
                <h1 className='header__yellow'>Dodaj admina</h1>
                <form className="section--column-0" onSubmit={handleSubmit}>
                    <div className="section--row-1">
                        <Input pageText={formText.name} id='name' name='name' showError={showErrors} minLength={1} maxLength={40} inputDisabled={inputsDisabled} pattern={TextRegexString} />
                        <Input pageText={formText.userName} id='userName' name='userName' showError={showErrors} minLength={1} maxLength={40} inputDisabled={inputsDisabled} pattern={TextAndNumbersRegexString} />
                    </div>
                    <Input pageText={formText.email} id='email' name='email' type='email' showError={showErrors} maxLength={70} inputDisabled={inputsDisabled} pattern={EmailRegexString} />
                    <div className="section--row-1">
                        <Input pageText={formText.password} id="password" name="password" type="password" showError={showErrors} minLength={8} maxLength={100} inputDisabled={inputsDisabled} pattern={PasswordRegexString} />
                        <Input pageText={formText.repeatPassword} id="repeat_password" name="repeatPassword" type="password" showError={showErrors} minLength={8} maxLength={100} inputDisabled={inputsDisabled} pattern={PasswordRegexString} />
                    </div>
                    <input
                        type="submit"
                        className="input__element input__button"
                        onClick={() => setShowErrors(true)}
                        disabled={inputsDisabled}
                        value={inputsDisabled ? "Przekierowuję..." : "Stwórz"}
                    />
                    <span className={`input__span${submitError ? " input__span--visible" : ""}`}>{submitError}</span>
                </form>
            </div>
        </Page>
    )
}

export default CreateAdmin